import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Send, AttachFile, Image, EmojiEmotions,
} from '@material-ui/icons';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { useAlert } from 'react-alert';
import { parseISO, formatDistanceToNow } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { Emoji, Picker as EmojiPicker } from 'emoji-mart';
import ChatsActions from '../../../store/ducks/chat';

import ScrollContainer from '../../../components/ScrollMessage';
import {
  MainContainer,
  MainMessagesContainer,
  MessageContainer,
  MessageText,
  MessageDate,
  TextInputComponent,
  TextInputContainer,
  TextInputMainContainer,
  MessageRow,
  SendTextButton,
  UploadButton,
  EmployeeNameText,
  EmojiPickerContainer,
  HeaderMainChat,
  ClientAvatar,
  TagContainer,
} from './styles';
import AvatarImage from '../../../assets/image/avatar.jpg';
import RenderFileMessage from '../../../components/RenderFileMessage';
import 'emoji-mart/css/emoji-mart.css';

export default function MainChatComponent({
  chatSelected,
  chats,
  userData,
  chatStatusSelected,
  setChatStatusSelected,
  scrollContainerRef,
}) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [messageText, setMessageText] = useState('');

  function sendMessage() {
    const data = {
      chat_id: chatSelected,
      message: messageText,
      message_type: 'text',
    };
    setMessageText('');
    chatStatusSelected !== 2 && setChatStatusSelected(1);
    return dispatch(ChatsActions.getSendMessageRequest(data));
  }

  function sendEmote(emojiObj) {
    console.log(emojiObj);
    const data = {
      chat_id: chatSelected,
      // message: emojiObj.names.length > 1 ? emojiObj.names[1].replace(/ /g, '_') : emojiObj.names[0].replace(/ /g, '_'),
      message: emojiObj.colons,
      message_type: 'emoji',
    };
    setMessageText('');
    chatStatusSelected !== 2 && setChatStatusSelected(1);
    setShowEmojiPicker(false);
    return dispatch(ChatsActions.getSendMessageRequest(data));
  }

  function uploadFile(file) {
    const fileData = file[0];
    alert.show(`Confimar o envio do arquivo? ${fileData.name}`, {
      title: 'Enviar arquivo',
      closeCopy: 'Cancelar',
      actions: [
        {
          copy: 'Enviar',
          onClick: () => dispatch(
            ChatsActions.sendFileMessageRequest(fileData, chatSelected),
          ),
        },
      ],
    });
  }

  return (
    <MainContainer>
      <MainMessagesContainer>
        {chatSelected
            && chats
              .filter((chat) => chat.id === chatSelected)
              .map((chat, index) => (
                <HeaderMainChat>
                  <span>
                    <ClientAvatar src={chat.user.avatar ? chat.user.avatar.url : AvatarImage} />
                    <p>{chat.user.full_name}</p>
                  </span>
                  <span>
                    {chat.tag && chat.tag.length > 0 && chat.tag.map((tag) => (
                      <TagContainer background={tag.background_color}>{tag.name}</TagContainer>
                    ))}
                  </span>
                </HeaderMainChat>
              ))}
        <ScrollContainer style={{ padding: 15 }} ref={scrollContainerRef}>
          {chatSelected
            && chats
              .filter((chat) => chat.id === chatSelected)[0]
              .message.map((message) => (
                <MessageRow
                  key={message.id}
                  style={{
                    justifyContent: message.from.employee
                      ? 'flex-end'
                      : 'flex-start',

                  }}
                >
                  {!message.from.employee && message.file_id ? (
                    <MessageContainer
                      style={{
                        width: '100%',
                        marginLeft: message.from.employee
                          ? 15
                          : 0,
                        marginRight: message.from.employee
                          ? 0
                          : 15,
                      }}
                      fromContainer={`${message.from.employee}`}
                    >
                      <RenderFileMessage file={message.file} />
                      <MessageDate>
                        {formatDistanceToNow(parseISO(message.createdAt), {
                          locale: ptLocale,
                        })}
                      </MessageDate>
                    </MessageContainer>
                  ) : (
                    <MessageContainer
                      style={{
                        marginRight: message.from.employee
                          ? 15
                          : 0,
                        marginLeft: message.from.employee
                          ? 0
                          : 12,
                      }}
                      fromContainer={`${message.from.employee}`}
                    >
                      {message.file_id && message.message_type === 'application/pdf' && (
                        <a href={message.file.url} target="_blank">
                          {message.message}
                        </a>
                      )}
                      {message.file_id && message.message_type.indexOf('image') != -1 && (
                      <a href={message.file.url} target="_blank">
                        <img
                          src={message.file.url}
                          alt="imagem enviada pelo usuario"
                          style={{
                            width: '100%',
                            // maxWidth: '50%',
                            height: 'auto',
                          }}
                        />
                      </a>
                      )}
                      {message.message_type === 'emoji' && (
                        <Emoji emoji={message.message} size={28} />
                      )}
                      {message.message_type === 'webview' && (
                      <a href={message.message} target="_blank">
                        {message.message}
                      </a>
                      )}
                      {!message.file_id && message.message_type !== 'emoji' && (
                        <MessageText>{message.message}</MessageText>
                      )}
                      {message.from.employee && (
                        <EmployeeNameText>
                          Enviado por:
                          {' '}
                          {message.from.full_name}
                        </EmployeeNameText>
                      )}
                      <MessageDate>
                        {formatDistanceToNow(parseISO(message.createdAt), {
                          locale: ptLocale,
                        })}
                      </MessageDate>
                    </MessageContainer>
                  )}
                </MessageRow>
              ))}
        </ScrollContainer>
      </MainMessagesContainer>
      {chatSelected && (
        <TextInputMainContainer>
          <UploadButton onClick={() => setOpenPdfModal(true)}>
            <AttachFile />
          </UploadButton>
          {/* <input
            accept="application/pdf"
            id="contained-button-file"
            style={{ display: 'none' }}
            type="file"
            onChange={(file) => uploadFile(file.target.files)}
          />
          <label htmlFor="contained-button-file">
            <UploadButton>
              <AttachFile />
            </UploadButton>
          </label> */}
          {' '}
          <input
            accept="image/x-png,image/gif,image/jpeg"
            id="image-upload-file"
            style={{ display: 'none' }}
            type="file"
            onChange={(file) => uploadFile(file.target.files)}
          />
          <label htmlFor="image-upload-file">
            <UploadButton>
              <Image />
            </UploadButton>
          </label>
          {showEmojiPicker && (
            <EmojiPickerContainer>
              <EmojiPicker
                onSelect={(emojObj) => sendEmote(emojObj)}
                i18n={{
                  search: 'Pesquisar',
                  clear: 'Limpar',
                  notfound: 'Emoji não encontrado',
                  skintext: 'Escolha o tom padrão',
                  categories: {
                    search: 'Resultado da pesquisa',
                    recent: 'Usado frequentimente',
                    smileys: 'Smileys & Reações',
                    people: 'Pessoas & Corpo',
                    nature: 'Animais & Natureza',
                    foods: 'Comida & Bebida',
                    activity: 'Atividades',
                    places: 'Viagem & Lugares',
                    objects: 'Objetos',
                    symbols: 'Simbolos',
                    flags: 'Bandeiras',
                    custom: 'Customizados',
                  },
                }}
              />
              {/* <EmojiPicker onEmojiClick={(event, emojObj) => sendEmote(emojObj)} /> */}
              {/* <EmojiPicker onEmojiClick={(event, emojObj) => console.log(emojObj)} /> */}
            </EmojiPickerContainer>
          )}
          <TextInputContainer>
            <TextInputComponent
              rows={4}
              placeholder="Digite sua mensagem"
              value={messageText}
              onChange={({ target }) => setMessageText(target.value)}
              onKeyPress={({ key }) => key === 'Enter' && sendMessage()}
            />
          </TextInputContainer>
          <SendTextButton onClick={() => sendMessage()}>
            <Send />
          </SendTextButton>
          {/* <SendTextButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
            <EmojiEmotions />
          </SendTextButton> */}
        </TextInputMainContainer>
      )}
      <Dialog
        fullScreen
        open={openPdfModal}
        onClose={() => setOpenPdfModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Criador PDF</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Após concluir e fechar essa janela, envie o arquivo para o cliente
          </DialogContentText> */}
          <iframe
            style={{
              width: '100%',
              height: '100%',
            }}
            src="http://scriba.guruastral.com"
            title="Gerador pdf"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenPdfModal(false);
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {}}
            color="primary"
          >
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>
    </MainContainer>
  );
}
