import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Add, Send, Edit, AlternateEmail, DateRange,
} from '@material-ui/icons';
import { parseISO, format } from 'date-fns';
import SunEditor from 'suneditor-react';

import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Modal,
} from '@material-ui/core';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import ChatsActions from '../../../store/ducks/chat';
import QuickMessagesActions from '../../../store/ducks/quickMessages';
import AvatarImage from '../../../assets/image/avatar.jpg';
import ScrollContainer from '../../../components/ScrollContainer';
import colors from '../../../styles/colors';
import 'suneditor/dist/css/suneditor.min.css';

import {
  FilesHeaderContainer,
  FilesTableContainer,
  RBAvatar,
  RBChatStatus,
  RBChatStausText,
  RBMainText,
  RBRow,
  RBUserDataContainer,
  RightBar,
  FilesDataContainer,
  FileTableRow,
  NoteTextArea,
} from './styles';

export default function RightBarComponent({
  chatSelected,
  chats,
  userData,
  noteChat,
  setNoteChat,
  setChatStatusSelected,
  quickMessageStore,
  tagsStore,
  chatStatusSelected,
}) {
  const dispatch = useDispatch();
  const filesChatStore = useSelector((store) => store.chat.filesChat);
  const [quickMessageValue, setQuickMessageValue] = useState({ value: 0, label: 'Selecione uma mensagem rapida' });
  const [tagValue, setTagValue] = useState({ value: 0, label: 'Selecione uma tag' });
  const [newQuickMsgStatus, setNewQuickMsgStatus] = useState(false);
  const [fileChatStatus, setFileChatStatus] = useState(false);
  const [newQuickMsgText, setNewQuickMsgText] = useState('');
  const [sendWebViewStatus, setSendWebViewStatus] = useState(false);
  const [createTagStatus, setCreateTagStatus] = useState(false);
  const [sendWebViewText, setSendWebViewText] = useState('');
  const [newTagName, setNewTagName] = useState('');
  const [backgroundTag, setBackgroundTag] = useState('#ffffff');

  function RenderCategoryChat({
    status, cursor, employeeId, ...props
  }) {
    const statusData = {
      text: '',
      background: '#fff',
    };

    if (status === 0) {
      statusData.text = 'Não respondida';
      statusData.background = '#ff6100';
    } else if (status === 1) {
      statusData.text = 'Respondida';
      statusData.background = '#62d300';
    } else if (status === 2) {
      statusData.text = userData.id === employeeId ? 'Desselecionar' : 'Selecionar';
      statusData.background = userData.id === employeeId ? '#4e409e' : '#383353';
    }

    return (
      <RBChatStatus
        style={{ background: statusData.background, cursor }}
        {...props}
      >
        <RBChatStausText>{statusData.text}</RBChatStausText>
      </RBChatStatus>
    );
  }

  function updateChatStatusTabs(chat, chat_status) {
    if (chat.employee_selected_id) {
      setChatStatusSelected(2);
    } else {
      setChatStatusSelected(chat_status);
    }

    return dispatch(ChatsActions.updateChatStatusRequest(chat.id, chat_status));
  }

  function createQuickMessage() {
    dispatch(QuickMessagesActions.createQuickMessagesRequest(newQuickMsgText));

    setNewQuickMsgText('');
    return setNewQuickMsgStatus(false);
  }

  function updateQuickMessage() {
    dispatch(QuickMessagesActions.updateQuickMessagesRequest(quickMessageValue.value, newQuickMsgText));
    setQuickMessageValue({ value: 0, label: 'Selecione uma mensagem rapida' });
    setNewQuickMsgText('');
    return setNewQuickMsgStatus(false);
  }

  const customStyleSelect = {
    container: (styles) => ({
      ...styles,
      width: '100%',
    }),

    singleValue: (styles, { data }) => ({
      ...styles,
      color: data.value === 0 ? '#7a7a7a' : 'black',
      fontWeight: '500',
    }),
  };

  const customTagStyleSelect = {
    option: (style, { data }) => ({
      ...style,
      color: '#fff',
      backgroundColor: data.background,
    }),
    container: (styles) => ({
      ...styles,
      width: '100%',
    }),

    singleValue: (styles, { data }) => ({
      ...styles,
      color: '#fff',
      backgroundColor: data.background,
      padding: 10,
      fontWeight: '500',
    }),
  };

  function sendMessage() {
    const data = {
      chat_id: chatSelected,
      message: quickMessageValue.label,
      message_type: 'quickmessage',
    };
    setQuickMessageValue({ value: 0, label: 'Selecione uma mensagem rapida' });
    chatStatusSelected !== 2 && setChatStatusSelected(1);
    return dispatch(ChatsActions.getSendMessageRequest(data));
  }

  function addChatTag(chat) {
    if (chat.tag.length === 3) {
      return toast.error('O chat possui o limite de 3 tag');
    }
    const data = {
      tagId: tagValue.value,
      chatId: chatSelected,
    };
    setTagValue({ value: 0, label: 'Selecione uma tag' });
    return dispatch(ChatsActions.addChatTagRequest(data));
  }

  function sendWebView() {
    const data = {
      chat_id: chatSelected,
      message: sendWebViewText,
      message_type: 'webview',
    };
    setSendWebViewText('');
    setSendWebViewStatus(false);
    return dispatch(ChatsActions.getSendMessageRequest(data));
  }

  function createTag() {
    if (!newTagName || !backgroundTag) {
      return toast.error('Todos os campos são obrigatorios');
    }

    const data = {
      name: newTagName,
      background_color: backgroundTag,
    };
    setNewTagName('');
    setBackgroundTag('');
    setCreateTagStatus(false);
    return dispatch(ChatsActions.createTagRequest(data));
  }

  function handleFilesChat(chat) {
    dispatch(ChatsActions.getChatFilesRequest(chat.id));
    setFileChatStatus(true);
  }

  return (
    <RightBar>
      <ScrollContainer>
        {chatSelected
          && chats
            .filter((chat) => chat.id === chatSelected)
            .map((chat, index) => (
              <div key={index}>
                <RBUserDataContainer
                  style={{
                    backgroundColor:
                      userData.id === chat.employee_selected_id
                        ? '#383353'
                        : colors.primary,
                  }}
                >
                  <RBAvatar
                    src={chat.user.avatar ? chat.user.avatar.url : AvatarImage}
                  />
                  {/* <RBMainText>
                    Nome:
                    {' '}
                    {chat.user.full_name}
                  </RBMainText> */}
                  <RBMainText>
                    <AlternateEmail color="tertiary" />
                    Email
                    :
                    {' '}
                    {chat.user.email}
                  </RBMainText>
                  <RBMainText>
                    <DateRange color="tertiary" />
                    Dt Nascimento
                    :
                    {' '}
                    {`${chat.user.day_birth}/${chat.user.month_birth}/${chat.user.year_birth}`}
                  </RBMainText>
                </RBUserDataContainer>
                <RBRow style={{ marginTop: 15 }}>
                  Categoria:
                  {' '}
                  <RenderCategoryChat
                    cursor="auto"
                    employeeId={chat.employee_selected_id}
                    status={chat.chat_status}
                  />
                </RBRow>
                <RBRow style={{ marginTop: 15 }}>
                  Selecionar chat:
                  {' '}
                  <RenderCategoryChat
                    employeeId={chat.employee_selected_id}
                    cursor="pointer"
                    status={2}
                    onClick={() => updateChatStatusTabs(chat, 2)}
                  />
                </RBRow>
                <RBRow style={{ marginTop: 15 }}>Categorizar</RBRow>
                <RBRow style={{ marginTop: 15 }}>
                  <RenderCategoryChat
                    employeeId={chat.employee_selected_id}
                    cursor="pointer"
                    status={0}
                    onClick={() => updateChatStatusTabs(chat, 0)}
                  />

                  <RenderCategoryChat
                    employeeId={chat.employee_selected_id}
                    cursor="pointer"
                    status={1}
                    onClick={() => updateChatStatusTabs(chat, 1)}
                  />
                </RBRow>
                <RBRow style={{ marginTop: 30 }}>
                  Mensagem rapida
                  <div>
                    <Tooltip title="Nova mensagem rapida">
                      <IconButton
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setNewQuickMsgText('');
                          setNewQuickMsgStatus(true);
                        }}
                      >
                        <Add />
                      </IconButton>
                    </Tooltip>
                    {quickMessageValue.value !== 0 && (
                    <Tooltip title="Editar">
                      <IconButton
                        size="small"
                        variant="contained"
                        color="primary"
                        disabled={quickMessageValue.value === 0}
                        onClick={() => {
                          setNewQuickMsgText(quickMessageValue.label);
                          setNewQuickMsgStatus(true);
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    )}
                    <Tooltip title="Enviar">
                      <IconButton
                        size="small"
                        variant="contained"
                        color="secondary"
                        disabled={quickMessageValue.value === 0}
                        onClick={() => sendMessage()}
                      >
                        <Send />
                      </IconButton>
                    </Tooltip>
                  </div>
                </RBRow>
                <RBRow>
                  <Select
                    options={quickMessageStore}
                    placeholder="Selecione uma mensagem rapida"
                    value={quickMessageValue}
                    onChange={(value) => setQuickMessageValue(value)}
                    styles={customStyleSelect}
                  />
                </RBRow>
                <RBRow style={{ marginTop: 30 }}>
                  Adicionar tag
                  <div>
                    <Tooltip title="Adicionar nova Tag">
                      <IconButton
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setCreateTagStatus(true);
                        }}
                      >
                        <Add />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Enviar">
                      <IconButton
                        size="small"
                        variant="contained"
                        color="secondary"
                        disabled={tagValue.value === 0}
                        onClick={() => addChatTag(chat)}
                      >
                        <Send />
                      </IconButton>
                    </Tooltip>
                  </div>
                </RBRow>
                <RBRow>
                  <Select
                    options={tagsStore}
                    placeholder="Selecione uma tag"
                    // value={tagValue}
                    onChange={(value) => setTagValue(value)}
                    styles={customTagStyleSelect}
                  />
                </RBRow>
                <RBRow style={{ marginTop: 30 }}>
                  Historico de arquivos
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleFilesChat(chat)}
                  >
                    Abrir
                  </Button>
                </RBRow>
                <RBRow style={{ marginTop: 30 }}>
                  Enviar Webview
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setSendWebViewStatus(true)}
                  >
                    Enviar
                  </Button>
                </RBRow>
                <RBRow style={{ marginTop: 30 }}>
                  Notas
                  {' '}
                  {/* <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(
                      ChatsActions.getNoteChatRequest({
                        chatId: chat.id,
                        note: noteChat,
                      }),
                    )}
                  >
                    Atualizar
                  </Button> */}
                </RBRow>
                <RBRow style={{ marginTop: 15, height: '250px' }}>
                  <NoteTextArea
                    multiline
                    onBlur={() => dispatch(
                      ChatsActions.getNoteChatRequest({
                        chatId: chat.id,
                        note: noteChat,
                      }),
                    )}
                    value={noteChat}
                    onChange={({ target }) => setNoteChat(target.value)}
                    rows={4}
                    placeholder="Sem notas no momento"
                  />
                </RBRow>
              </div>
            ))}
      </ScrollContainer>
      <Dialog
        fullScreen
        open={newQuickMsgStatus}
        onClose={() => setNewQuickMsgStatus(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Mensagens rapida</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {quickMessageValue.value !== 0 ? 'Atualizar mensagem rapida' : 'Digite o texto da nova mensagem rapida'}
          </DialogContentText>
          <NoteTextArea
            multiline
            rows={4}
            placeholder="Sem notas no momento"
            value={newQuickMsgText}
            style={{
              height: '60%',
            }}
            onChange={({ target }) => setNewQuickMsgText(target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setNewQuickMsgStatus(false);
              return setNewQuickMsgText('');
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => (quickMessageValue.value !== 0
              ? updateQuickMessage()
              : createQuickMessage())}
            color="primary"
          >
            {quickMessageValue.value !== 0 ? 'Atualizar' : 'Criar'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={sendWebViewStatus}
        onClose={() => setSendWebViewStatus(false)}
        aria-labelledby="form-dialog-title"

      >
        <DialogTitle id="form-dialog-title">Url Webview</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Digite a URL para enviar
          </DialogContentText>
          <NoteTextArea
            placeholder="Ex: https://google.com"
            value={sendWebViewText}
            style={{
              width: 300,
            }}
            onChange={({ target }) => setSendWebViewText(target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSendWebViewStatus(false);
              return setSendWebViewText('');
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => sendWebView()}
            color="primary"
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={createTagStatus}
        onClose={() => setCreateTagStatus(false)}
        aria-labelledby="form-dialog-title"

      >
        <DialogTitle id="form-dialog-title">Nova tag</DialogTitle>
        <DialogContent style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <DialogContentText>
            Digite o nome da tag (max 15 caracter)
          </DialogContentText>
          <NoteTextArea
            placeholder="Ex: Ligar mais tarde"
            maxLength={15}
            value={newTagName}
            style={{
              width: 300,
            }}
            onChange={({ target }) => setNewTagName(target.value)}
          />
          <DialogContentText style={{
            marginTop: 15,
          }}
          >
            Selecione a cor para o fundo da tag
          </DialogContentText>
          <ChromePicker
            color={backgroundTag}
            onChangeComplete={(color) => setBackgroundTag(color.hex)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCreateTagStatus(false);
              return setNewTagName('');
            }}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => createTag()}
            color="primary"
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={fileChatStatus}
        onClose={() => setFileChatStatus(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',

        }}
      >
        <FilesTableContainer>
          <RBRow style={{ backgroundColor: colors.primary, justifyContent: 'flex-end' }}>
            <Button onClick={() => setFileChatStatus(false)} color="secondary">
              Fechar
            </Button>
          </RBRow>
          <RBRow style={{ backgroundColor: colors.primary, marginBottom: 15 }}>
            <FilesHeaderContainer>
              <span>Arquivo</span>
            </FilesHeaderContainer>
            <FilesHeaderContainer>
              <span>Data criação</span>
            </FilesHeaderContainer>
          </RBRow>
          <ScrollContainer>
            {filesChatStore.map((file) => (
              <FileTableRow>
                <FilesDataContainer>
                  <a href={file.file.url} target="_blank">{file.file.name}</a>
                </FilesDataContainer>
                <FilesDataContainer>
                  <span>{format(parseISO(file.createdAt), 'dd/MM/yyyy')}</span>
                </FilesDataContainer>
              </FileTableRow>
            ))}
          </ScrollContainer>
        </FilesTableContainer>
      </Modal>
    </RightBar>
  );
}
