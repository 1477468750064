import styled from 'styled-components';
import colors from '../../../styles/colors';
import BackgroundButtonImage from './backgroundbutton.png';
import BackgroundImage from './background-gray.png';

/**
 * Componentes RightBar
 */

export const RightBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.2;
  background: #fff;
`;

export const RBAvatar = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 25px;
  object-fit: cover;
`;

export const RBUserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  width: 100%;
  background-image: url(${BackgroundButtonImage});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
`;

export const RBMainText = styled.p`
display: flex;
flex-direction: row;
align-items: center;
  width: 100%;
  text-align: left;
  margin-bottom: 2px;
  font-weight: 500;
  color: #fff;
`;

export const RBRow = styled.div`
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
`;

export const RBChatStatus = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
`;

export const RBChatStausText = styled.p`
  color: #fff;
  font-weight: 700;
  font-size: 0.8em;
`;

export const FilesHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 40px;
  color: #fff;
  font-weight: 700;

`;

export const FilesDataContainer = styled.div`
  background: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  font-weight: 500;
  padding: 10px;

  a {
    color: ${colors.secondary};
    font-weight: 700;
    text-decoration: none;
  }

  span {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
  }
`;

export const FileTableRow = styled.div`
  width: 100%;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  margin-bottom: 5px;
  border-bottom: 0.1px solid #d6d6d6;
`;

export const FilesTableContainer = styled.div`
  height: 60vh;
  width: 75vw;
  background-image: url(${BackgroundImage});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  background-color: #fff;
`;

export const NoteTextArea = styled.textarea`
  resize: none;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  height: 100%;
  font-family: "Montserrat",sans-serif;
`;
