import styled from 'styled-components';
import colors from '../../styles/colors';
import BackgroundImage from './background.jpg';

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
  /* background: red; */
  background-image: url(${BackgroundImage});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
`;

export const InputContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  border-top: 5px solid ${colors.secondary};
`;
