import React, { useEffect, useState } from 'react';
import { parseISO, format } from 'date-fns';
import { Emoji } from 'emoji-mart';
import { Search, HighlightOff } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Dialog, DialogActions, DialogTitle,
} from '@material-ui/core';
import ChatsActions from '../../../store/ducks/chat';
import AvatarImage from '../../../assets/image/avatar.jpg';
import ScrollContainer from '../../../components/ScrollContainer';
import {
  TabsContainer,
  TabsButton,
  ChatContainer,
  AvatarComponent,
  MainContainerChat,
  MainTextChat,
  SecondaryTextChat,
  Sidebar,
  SearchClientContainer,
  SearchClientInput,
  SearchClientButton,
  TagsContainerChat,
  TagContainer,
} from './styles';

function RenderTitleChatContainer({ status, children }) {
  const statusData = {
    fontWeight: 500,
  };

  if (status === 0) {
    statusData.fontWeight = 700;
  }

  return (
    <MainTextChat style={{ fontWeight: statusData.fontWeight }}>
      {children}
    </MainTextChat>
  );
}

export default function SidebarComponent({
  chatStatusSelected,
  chats,
  chatSelected,
  handleChatStatus,
  setNoteChat,
  setChatSelected,
}) {
  const dispatch = useDispatch();
  const chatsState = useSelector((state) => state.chat.chats);
  const [clientSearch, setClientSearch] = useState('');
  const [clientSearchData, setClientSearchData] = useState(null);
  const [confirmTagDeleteStatus, setConfirmTagDeleteStatus] = useState(false);
  const [tagSelected, setTagSelected] = useState({
    tagId: '',
    chatId: '',
  });
  const sidebarBackgrounds = [
    {
      sidebar: '#ff6100',
      message: '#ff8c00',
    },
    {
      sidebar: '#6eed00',
      messageSelected: '#62d300',
      message: '#4aa000',
    },
    {
      sidebar: '#4e409e',
      message: '#383353',
    },
  ];

  useEffect(() => {
    window.addEventListener('keydown', handleSearchClientEnter);

    return () => {
      window.removeEventListener('keydown', handleSearchClientEnter);
    };
  }, [clientSearch]);

  useEffect(() => {
    if (chatsState && clientSearch.length > 0) {
      if (chatStatusSelected === 2) {
        const filterChatStatus = chatsState.filter((chat) => chat.employee_selected_id);
        const filtredChat = filterChatStatus.filter((chat) => chat.user.full_name
          .toLocaleUpperCase()
          .includes(clientSearch.toLocaleUpperCase()));
        return setClientSearchData(filtredChat);
      }
      const filterChatStatus = chatsState.filter((chat) => chat.chat_status === chatStatusSelected && !chat.employee_selected_id);
      const filtredChat = filterChatStatus.filter((chat) => chat.user.full_name
        .toLocaleUpperCase()
        .includes(clientSearch.toLocaleUpperCase()));
      return setClientSearchData(filtredChat);
    }
  }, [chatsState]);

  function handleBackground(chatId, chat) {
    if (chatStatusSelected === 2) {
      return [
        {
          sidebar: '#ff6100',
          message: '#ff8c00',
        },
        {
          sidebar: '#6eed00',
          message: chatId === chatSelected ? '#62d300' : '#4aa000',
        },
        {
          sidebar: '#4e409e',
          message: chat.chat_status === 0
            ? (chatId === chatSelected ? '#ff8c00' : '#ff6100')
            : (chatId === chatSelected ? '#62d300' : '#4aa000'),
        },
      ];
    }
    return [
      {
        sidebar: '#ff6100',
        // message: '#ff8c00',
        message: chatId === chatSelected ? '#ff8c00' : '#dd7702',
      },
      {
        sidebar: '#6eed00',
        message: chatId === chatSelected ? '#62d300' : '#4aa000',
      },
      {
        sidebar: '#4e409e',
        message: '#383353',
      },
    ];
  }

  function handleSearchClient(e) {
    if (clientSearch.length > 0) {
      if (chatStatusSelected === 2) {
        const filterChatStatus = chats.filter((chat) => chat.employee_selected_id);

        const filtredChat = filterChatStatus.filter((chat) => chat.user.full_name
          .toLocaleUpperCase()
          .includes(clientSearch.toLocaleUpperCase()));

        return setClientSearchData(filtredChat);
      }
      const filterChatStatus = chats.filter((chat) => chat.chat_status === chatStatusSelected);
      const filtredChat = filterChatStatus.filter((chat) => chat.user.full_name
        .toLocaleUpperCase()
        .includes(clientSearch.toLocaleUpperCase()));

      return setClientSearchData(filtredChat);
    }
  }

  function handleSearchClientEnter(e) {
    if (e) {
      if (e) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (clientSearch.length === 1) {
            setClientSearchData(null);
            return setClientSearch('');
          }
        }
      }
      if (e.key === 'Enter' && clientSearch.length > 0) {
        if (chatStatusSelected === 2) {
          const filterChatStatus = chats.filter((chat) => chat.employee_selected_id);

          const filtredChat = filterChatStatus.filter((chat) => chat.user.full_name
            .toLocaleUpperCase()
            .includes(clientSearch.toLocaleUpperCase()));

          return setClientSearchData(filtredChat);
        }
        const filterChatStatus = chats.filter((chat) => chat.chat_status === chatStatusSelected);
        const filtredChat = filterChatStatus.filter((chat) => chat.user.full_name
          .toLocaleUpperCase()
          .includes(clientSearch.toLocaleUpperCase()));

        return setClientSearchData(filtredChat);
      }
    }
  }

  function removeChatTag() {
    setConfirmTagDeleteStatus(false);
    return dispatch(ChatsActions.removeChatTagRequest(tagSelected));
  }

  return (
    <Sidebar
      style={{ backgroundColor: sidebarBackgrounds[chatStatusSelected].sidebar }}
    >
      <TabsContainer>
        <TabsButton
          style={{
            backgroundColor: chatStatusSelected === 0 ? '#ff6100' : '#db5000',
            color: chatStatusSelected === 0 ? '#fff' : '#d6d6d6',
          }}
          onClick={() => {
            setClientSearchData(null);
            setClientSearch('');
            return handleChatStatus(0);
          }}
        >
          Não respondidos
        </TabsButton>
        <TabsButton
          style={{
            backgroundColor: chatStatusSelected === 1 ? '#6eed00' : '#6fdb11',
            color: chatStatusSelected === 1 ? '#fff' : '#d6d6d6',
          }}
          onClick={() => {
            setClientSearchData(null);
            setClientSearch('');
            return handleChatStatus(1);
          }}
        >
          Respondidos
        </TabsButton>
        <TabsButton
          style={{
            backgroundColor: chatStatusSelected === 2 ? '#4e409e' : '#3a307a',
            color: chatStatusSelected === 2 ? '#fff' : '#d6d6d6',
          }}
          onClick={() => {
            setClientSearchData(null);
            setClientSearch('');
            return handleChatStatus(2);
          }}
        >
          Selecionados
        </TabsButton>
      </TabsContainer>
      <ScrollContainer>
        {clientSearchData && clientSearch.length > 0 && clientSearchData
          .map((chat, index) => (
            <ChatContainer
              key={index}
              onClick={() => {
                const chatFiltred = chats.filter(
                  (item) => item.id === chat.id,
                )[0];
                setNoteChat(chatFiltred.note ? chatFiltred.note : '');
                return setChatSelected(chat.id);
              }}
              style={{
                // background: sidebarBackgrounds[chatStatusSelected].message,
                background: handleBackground(chat.id, chat)[chatStatusSelected].message,
              }}
            >
              <AvatarComponent
                src={chat.user.avatar ? chat.user.avatar.url : AvatarImage}
              />
              <MainContainerChat>
                <RenderTitleChatContainer status={chat.chat_status}>
                  {chat.user.full_name}
                </RenderTitleChatContainer>
                <SecondaryTextChat>
                  {chat.message[chat.message.length - 1].message_type === 'emoji' ? (
                    <Emoji emoji={chat.message[chat.message.length - 1].message} size={18} />
                  ) : chat.message[chat.message.length - 1].message}
                </SecondaryTextChat>
                <RenderTitleChatContainer status={chat.chat_status}>
                  {format(
                    parseISO(chat.message[chat.message.length - 1].updatedAt),
                    'dd/MM/yyyy HH:mm',
                  )}
                </RenderTitleChatContainer>
              </MainContainerChat>
              <TagsContainerChat>
                {chat.tag && chat.tag.length > 0 && chat.tag.map((tag) => (
                  <TagContainer background={tag.background_color}>
                    {tag.name}
                    <button onClick={() => {
                      setTagSelected({
                        tagId: tag.id,
                        chatId: chat.id,
                      });
                      setConfirmTagDeleteStatus(true);
                    }}
                    >
                      <HighlightOff color="tertiary" />
                    </button>
                  </TagContainer>
                ))}
              </TagsContainerChat>
            </ChatContainer>
          ))}
        {clientSearchData === null && chats
          && chats
            .filter((chat) => (chatStatusSelected === 2
              ? chat.employee_selected_id
              : chat.chat_status === chatStatusSelected
                  && !chat.employee_selected_id))
            .map((chat, index) => (
              <ChatContainer
                key={index}
                onClick={() => {
                  const chatFiltred = chats.filter(
                    (item) => item.id === chat.id,
                  )[0];
                  setNoteChat(chatFiltred.note ? chatFiltred.note : '');
                  return setChatSelected(chat.id);
                }}
                style={{
                  // background: sidebarBackgrounds[chatStatusSelected].message,
                  background: handleBackground(chat.id, chat)[chatStatusSelected].message,
                }}
              >
                <AvatarComponent
                  src={chat.user.avatar ? chat.user.avatar.url : AvatarImage}
                />
                <MainContainerChat>
                  <RenderTitleChatContainer status={chat.chat_status}>
                    {chat.user.full_name}
                  </RenderTitleChatContainer>
                  <SecondaryTextChat>
                    {chat.message[chat.message.length - 1].message_type === 'emoji' ? (
                      <Emoji emoji={chat.message[chat.message.length - 1].message} size={18} />
                    ) : chat.message[chat.message.length - 1].message}
                  </SecondaryTextChat>
                  <RenderTitleChatContainer status={chat.chat_status}>
                    {format(
                      parseISO(chat.message[chat.message.length - 1].updatedAt),
                      'dd/MM/yyyy HH:mm',
                    )}
                  </RenderTitleChatContainer>
                </MainContainerChat>
                <TagsContainerChat>
                  {chat.tag && chat.tag.length > 0 && chat.tag.map((tag) => (
                    <TagContainer background={tag.background_color}>
                      {tag.name}
                      <button onClick={() => {
                        setTagSelected({
                          tagId: tag.id,
                          chatId: chat.id,
                        });
                        setConfirmTagDeleteStatus(true);
                      }}
                      >
                        <HighlightOff color="tertiary" />
                      </button>
                    </TagContainer>
                  ))}
                </TagsContainerChat>
              </ChatContainer>
            ))}
      </ScrollContainer>
      <SearchClientContainer>
        <SearchClientInput
          placeholder="Pesquisar cliente"
          value={clientSearch}
          onChange={({ target }) => setClientSearch(target.value)}
          onKeyPress={handleSearchClientEnter}
        />
        <SearchClientButton onClick={() => handleSearchClient()}>
          <Search />
        </SearchClientButton>
      </SearchClientContainer>
      <Dialog
        open={confirmTagDeleteStatus}
        onClose={() => setConfirmTagDeleteStatus(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Deseja desvincular a tag desse chat?</DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={() => setConfirmTagDeleteStatus(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => removeChatTag()} color="secondary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Sidebar>
  );
}
