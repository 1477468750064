import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import ChatsAction from '../ducks/chat';

import api from '../../services/api';

export function* getChats() {
  try {
    const { data } = yield call(api.get, 'chats');
    yield put(ChatsAction.getChatsSuccess(data));
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
  }
}

export function* updateChatStatus({ chatId, chatStatus }) {
  try {
    yield call(api.put, `chat-status/${chatId}/${chatStatus}`);
    yield call(getChats);
    yield put(ChatsAction.updateChatStatusSuccess());
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
  }
}

export function* getFilesChat({ chatId }) {
  try {
    const { data } = yield call(api.get, `/message-file-history/${chatId}`);
    yield put(ChatsAction.getChatFilesSuccess(data));
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
  }
}

export function* sendMessage({ data }) {
  try {
    yield call(api.post, 'messages', data);
    yield call(getChats);
    yield put(ChatsAction.getSendMessageSuccess());
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
  }
}

export function* sendFileMessage({ file, chatId }) {
  try {
    const fileForm = new FormData();
    fileForm.append('file', file);

    yield call(api.post, `file-message/${chatId}`, fileForm);
    yield call(getChats);
    yield put(ChatsAction.sendFileMessageSuccess());
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
  }
}

export function* noteChat({ data }) {
  try {
    yield call(api.post, `note-chat/${data.chatId}`, { note: data.note });
    yield call(getChats);
    yield put(ChatsAction.getNoteChatSuccess());
    return toast.success('Nota atualizada com sucesso');
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
    return toast.error('Falha no salvamento da nota');
  }
}

export function* getTag() {
  try {
    const { data } = yield call(api.get, 'tags');
    const formatedData = data.map((tag) => ({
      value: tag.id,
      label: tag.name,
      background: tag.background_color,
    }));
    yield put(ChatsAction.getTagSuccess(formatedData));
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
  }
}

export function* createTag({ data }) {
  try {
    yield call(api.post, 'tags', data);
    yield put(ChatsAction.createTagSuccess());
    yield call(getTag);
    return toast.success('Tag criada com sucesso');
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
    return toast.error('Falha na criação de tag');
  }
}

export function* addChatTag({ data }) {
  try {
    yield call(api.post, 'tags-client', data);
    yield put(ChatsAction.addChatTagSuccess());
    yield call(getChats);
    return toast.success('Tag adicionada ao chat com sucesso');
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
    return toast.error('Falha na adição de tag de tag');
  }
}

export function* removeChatTag({ data }) {
  try {
    yield call(api.put, 'tags-client', data);
    yield put(ChatsAction.removeChatTagSuccess());
    yield call(getChats);
    return toast.success('Tag desvinculada com sucesso');
  } catch (error) {
    yield put(ChatsAction.loadingFalse());
    return toast.error('Falha na adição de tag de tag');
  }
}
