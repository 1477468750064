import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";

import Chat from "../../pages/Chat";

const Routes = () => (
  <Switch>
    <Route exact path="/app" component={Chat} />
  </Switch>
);

export default function App() {
  return <Routes />;
}
