import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* Types & Action Creators */

const { Types, Creators } = createActions({
  getChatsRequest: null,
  getChatsSuccess: ['data'],
  getSendMessageRequest: ['data'],
  getSendMessageSuccess: null,
  sendFileMessageRequest: ['file', 'chatId'],
  sendFileMessageSuccess: null,
  getNoteChatRequest: ['data'],
  getNoteChatSuccess: null,
  getChatFilesRequest: ['chatId'],
  getChatFilesSuccess: ['data'],
  updateChatStatusRequest: ['chatId', 'chatStatus'],
  updateChatStatusSuccess: null,
  getTagRequest: null,
  getTagSuccess: ['data'],
  createTagRequest: ['data'],
  createTagSuccess: null,
  addChatTagRequest: ['data'],
  addChatTagSuccess: null,
  removeChatTagRequest: ['data'],
  removeChatTagSuccess: null,
  loadingFalse: null,
});

export const ChatsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  chats: [],
  filesChat: [],
  selectedChat: {},
  tags: [],
  loading: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CHATS_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.GET_CHATS_SUCCESS]: (state, action) => state.merge({ loading: false, chats: action.data }),
  [Types.GET_SEND_MESSAGE_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.GET_SEND_MESSAGE_SUCCESS]: (state) => state.merge({ loading: false }),
  [Types.SEND_FILE_MESSAGE_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.SEND_FILE_MESSAGE_SUCCESS]: (state) => state.merge({ loading: false }),
  [Types.GET_NOTE_CHAT_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.GET_NOTE_CHAT_SUCCESS]: (state) => state.merge({ loading: false }),
  [Types.GET_CHAT_FILES_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.GET_CHAT_FILES_SUCCESS]: (state, action) => state.merge({
    loading: false, filesChat: action.data,
  }),
  [Types.UPDATE_CHAT_STATUS_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.UPDATE_CHAT_STATUS_SUCCESS]: (state) => state.merge({ loading: false }),
  [Types.CREATE_TAG_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.CREATE_TAG_SUCCESS]: (state) => state.merge({ loading: false }),
  [Types.GET_TAG_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.GET_TAG_SUCCESS]: (state, action) => state.merge({ loading: false, tags: action.data }),
  [Types.ADD_CHAT_TAG_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.ADD_CHAT_TAG_SUCCESS]: (state) => state.merge({ loading: false }),
  [Types.REMOVE_CHAT_TAG_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.REMOVE_CHAT_TAG_SUCCESS]: (state) => state.merge({ loading: false }),
  [Types.LOADING_FALSE]: (state) => state.merge({ loading: false }),
});
