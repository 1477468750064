import { blue } from '@material-ui/core/colors';
import React, { useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

function ScrollMessage(props) {
  const scrollRef = useRef();
  useEffect(() => {
    if (scrollRef.current) {
      return scrollRef.current.scrollToBottom();
    }
  }, [props]);
  return (
    <Scrollbars
      ref={scrollRef}
      renderTrackHorizontal={(props) => (
        <div
          {...props}
          className="track-horizontal"
          style={{
            display: 'none',

          }}
        />
      )}
      renderThumbHorizontal={(props) => (
        <div
          {...props}
          className="thumb-horizontal"
          style={{ display: 'none' }}
        />
      )}
      renderTrackVertical={(props) => (
        <div
          {...props}
          className="track-vertical"
          style={{ display: 'none' }}
        />
      )}
      renderThumbVertical={(props) => (
        <div
          {...props}
          className="thumb-vertical"
          style={{ display: 'none' }}
        />
      )}
    >
      {props.children}
    </Scrollbars>
  );
}

export default ScrollMessage;
