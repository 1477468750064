/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button, TextField, Typography,
} from '@material-ui/core';

import api from '../../services/api';
import { ContainerLogin, InputContainer } from './styles';
import UserActions from '../../store/ducks/user';
import { login, USERDATA_KEY } from '../../services/auth';

export default function Login(props) {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  async function handleLoggin() {
    try {
      const { data } = await api.post('/sessions', userData);
      login(data.token);
      props.history.push('/app');
      sessionStorage.setItem(USERDATA_KEY, JSON.stringify(data.user));
      dispatch(UserActions.setUserData(data.user));
      dispatch(UserActions.loadingFalse());
      return toast.success('Login realizado com sucesso');
    } catch (error) {
      console.log(error);
      return toast.error('Usuario ou senha não estão correto');
    }
  }

  return (
    <ContainerLogin>
      <InputContainer>
        <Typography color="secondary" variant="h5">
          Login
        </Typography>
        <TextField
          id="outlined-basic"
          label="Usuario"
          fullWidth
          type="text"
          variant="outlined"
          color="secondary"
          value={userData.email}
          onChange={(event) => setUserData({ ...userData, email: event.target.value })}
          style={{ margin: '20px 0' }}
        />
        <TextField
          id="outlined-basic"
          label="Senha"
          fullWidth
          color="secondary"
          value={userData.password}
          onChange={(event) => setUserData({ ...userData, password: event.target.value })}
          type="password"
          variant="outlined"
        />
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: 25 }}
          onClick={() => handleLoggin()}
        >
          Entrar
        </Button>
      </InputContainer>
    </ContainerLogin>
  );
}
