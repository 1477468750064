import styled from 'styled-components';
import colors from '../../../styles/colors';
import BackgroundImage from './background-gray.png';

/**
 * Componentes sidebar
 */

export const Sidebar = styled.div`
  background: #04b6ed;
  flex: 0.23;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  transition: background 200ms;
  background-image: url(${BackgroundImage});
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
`;

export const ChatContainer = styled.div`
  background: ${colors.primary};
  cursor: pointer;
  width: 96%;
  margin-left: 10px;
  margin: 10px 0;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

export const AvatarComponent = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  image-rendering: optimizeSpeed;
  object-fit: cover;
`;

export const MainContainerChat = styled.div`
  width: 60%;
`;

export const TagsContainerChat = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${({ background }) => (background || '#fff')};
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  border-radius: 2.5px;
  padding: 4px;
  margin-bottom: 3px;

  svg {
    font-size: 12px;
  }

  button {
    background: none;
    border: none;
    color: #fff;
    font-size: 9px;
    font-weight: bold;
  }
`;

export const MainTextChat = styled.p`
  color: #fff;
`;

export const SecondaryTextChat = styled.p`
  color: #f3f3f3;
  max-width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Componentes Tabs
 */

export const TabsContainer = styled.div`
  width: 100%;
`;

export const TabsButton = styled.button`
  width: 33.3333%;
  color: #fff;
  font-weight: 700;
  border: none;
  height: 35px;
  transition: background 300ms;
  transition: color 300ms;
  font-size: 0.6em;
  transition: background 200ms;
`;

export const SearchClientContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  background: #fff;
`;

export const SearchClientInput = styled.input`
  height: 50px;
  width: 85%;
  border: none;
  background: #fff;
  padding: 10px;
`;

export const SearchClientButton = styled.button`
  height: 50px;
  width: 15%;
  border: none;
  background: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
`;
